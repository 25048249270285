import { FC } from 'react';

import { TCharacterEssences } from '@lib/core/characters/types';
import { TProductCategory } from '@lib/core/products/types';
import { VH_VARIANTS } from '@lib/tools/shared/helpers/consts';

import ProgressBar, { IProgressBarVariant } from '@components/web/src/components/ProgressBar/ProgressBar';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { TOTAL_INDICATOR_AMOUNT } from '@components/web/src/foundations/consts';
import { Text } from '@components/web/src/foundations/Text/Text';

interface Props extends TCharacterEssences {
  variant?: TProductCategory;
}
const ResultEssenceItem: FC<Props> = ({ score, name, variant = VH_VARIANTS.LIGHT }) => {
  return (
    <div className="result-essence-item-container">
      <span className="essence-title">
        <Text color={STATIC_COLORS.base.black} size="body2" text={name} weight="medium" />
      </span>
      <ProgressBar
        className="result-progress-bar"
        progressPercentage={(score / TOTAL_INDICATOR_AMOUNT) * 100}
        variant={variant as IProgressBarVariant}
      />
    </div>
  );
};

export default ResultEssenceItem;
