import { FC } from 'react';

import { TCharacterEssences } from '@lib/core/characters/types';
import { TProductCategory } from '@lib/core/products/types';
import { localeKiosk } from '@lib/tools/locale/source/web/kiosk';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';

import ResultEssenceItem from '@components/web/src/components/CharacterDetails/ResultEssenceItem';

export type Props = {
  productCategory: TProductCategory;
  characterImage: string;
  characterName: string;
  characterCaption: string;
  sortedRelatedCharacterEssences: TCharacterEssences[];
  characterDescription: string;
};
export const KioskCharacterDetails: FC<Props> = ({
  productCategory,
  characterImage,
  characterName,
  characterCaption,
  sortedRelatedCharacterEssences,
  characterDescription,
}) => {
  const { characterDetails } = localeKiosk;
  return (
    <div className="character-details-container">
      <div className="character-image" style={{ backgroundImage: `url('${characterImage}')` }} />
      <div className="character-summary">
        <p className="character-title">
          <LocaleFragment message={characterDetails.characterTitle} />
        </p>
        <p className={`character-name ${productCategory}`}>#{characterName}</p>
        <p className="character-caption">{characterCaption}</p>
        <div className="character-essence-group">
          {sortedRelatedCharacterEssences.map(essence => (
            <ResultEssenceItem key={essence.identifier} {...essence} variant={productCategory} />
          ))}
        </div>
        <p className="character-description">
          <LocaleFragment message={characterDescription} />
        </p>
      </div>
    </div>
  );
};

export default KioskCharacterDetails;
