import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useCharacters } from '@lib/core/characters/hooks';
import { isCharacterByIdentifiers, isCharacterByServiceProductCategory } from '@lib/core/characters/utils/filters';
import { TProductInstance } from '@lib/core/products/types';
import { useRetailerLocation } from '@lib/core/retailers/hooks/retailerLocation';
import { PREFERENCES_QUERY, PRODUCT_PREFERENCES_URL_PARAM } from '@lib/core/service/consts';
import { useApp } from '@lib/core/service/hooks';
import { useKioskRoot } from '@lib/core/service/hooks/useKioskRoot';
import { fetchSituationalResultPageProducts, resetResultPageProducts } from '@lib/core/service/slices/kioskRootSlice';
import { prependBasename } from '@lib/core/service/utils';
import { useProductList, useUser } from '@lib/core/users/hooks';
import { useFidelityCard } from '@lib/core/users/hooks/useFidelityCard';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { CHARACTERS_URL_PARAM, PRODUCT_CATEGORY_QUERY } from '@lib/tools/shared/helpers/consts';
import { useAddons } from '@lib/tools/views/hooks';
import { PAGES } from '@lib/tools/views/urls';

import ResultPageSkeleton from '@components/web/src/organisms/Skeleton/ResultPageSkeleton/ResultPageSkeleton';
import SituationalResultPage from '@components/web/src/pages/kiosk/ResultPage/SituationalResultPage';

export interface IGroupedProduct {
  characterId: string;
  products: TProductInstance[];
}

const SituationalResultContainer: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const [showContent, setShowContent] = useState(false);
  const [groupedProducts, setGroupedProducts] = useState<IGroupedProduct[] | null>(null);

  const { locale, productCategory } = useApp();
  const { isFetchingUserData, userCharacters } = useUser();
  const { characters, isCharactersLoading } = useCharacters();
  const { retailerStoreType } = useRetailerLocation();
  const { isEnableLocationMapAddon, isShowProductLocationAddon, isEnableVusionAddon } = useAddons();
  const { isFidelityCardScanned } = useFidelityCard();
  const { products, isProductsLoading } = useKioskRoot();
  const { wishlistProductIds, handleUpdateWishlistProductList, isProductListLoading } = useProductList();

  const characterStringFromUrl: string | null = searchParams.get(CHARACTERS_URL_PARAM) || '';
  const preferencesFromUrl: string | null = searchParams.get(PRODUCT_PREFERENCES_URL_PARAM) || '';

  const translatedCharactersData = characterStringFromUrl
    ? characters.filter(character =>
        isCharacterByIdentifiers(character, [...new Set(characterStringFromUrl.split('-'))]),
      )
    : userCharacters.filter(character => isCharacterByServiceProductCategory(character));

  const charactersIdentifiers = translatedCharactersData.map(character => character.identifier);

  useEffect(() => {
    if (!products.length) {
      const params = {
        [PRODUCT_CATEGORY_QUERY]: productCategory,
        offset: 0,
        ...(preferencesFromUrl && { [PREFERENCES_QUERY]: preferencesFromUrl }),
      };

      dispatch(fetchSituationalResultPageProducts({ charactersIdentifiers, params }));
    }

    return () => {
      if (!window.location.pathname.includes(PAGES.vinhood.product)) {
        dispatch(resetResultPageProducts());
      }
    };
  }, []);

  useEffect(() => {
    if (!groupedProducts && products.length) {
      const groupedProductsData: IGroupedProduct[] = products
        .filter(productsData => productsData.length)
        .map(productArray => ({
          characterId: productArray[0]?.character?.identifier,
          products: productArray,
        }));

      setGroupedProducts(groupedProductsData);
    }
  }, [products]);

  const navigateToProductCatalogPage = (characterId?: string) =>
    navigate(prependBasename(PAGES.vinhood.catalog, characterId && { [CHARACTERS_URL_PARAM]: characterId }));

  useEffect(() => {
    const isDataLoading = isProductsLoading || isFetchingUserData || isCharactersLoading;

    if (!isDataLoading && !!groupedProducts) {
      setTimeout(() => {
        setShowContent(true);
      }, 2000);
    }
  }, [isProductsLoading, isFetchingUserData, isCharactersLoading, characterStringFromUrl, groupedProducts]);

  useEffect(() => {
    MixpanelTracker.events.resultPageView(translatedCharactersData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {showContent ? (
        <SituationalResultPage
          shouldHideFeedback
          charactersData={translatedCharactersData}
          groupedProducts={groupedProducts}
          handleUpdateWishlistProductList={handleUpdateWishlistProductList}
          isEnableLocationMapAddon={isEnableLocationMapAddon}
          isEnableVusionAddon={isEnableVusionAddon}
          isProductListLoading={isProductListLoading}
          isProductsLoading={isProductsLoading}
          isShowProductLocationAddon={isShowProductLocationAddon}
          locale={locale}
          navigateToProductCatalogPage={navigateToProductCatalogPage}
          productCategory={productCategory}
          shouldHideWishlist={!isFidelityCardScanned}
          storeType={retailerStoreType}
          wishlistProductIds={wishlistProductIds}
        />
      ) : (
        <ResultPageSkeleton isFromSituationTest />
      )}
    </>
  );
};

export default SituationalResultContainer;
